.viewer {
  margin-top: 50px;
  width: 100%;
  height: 82%;

  img {
    display: block;
    margin: 5px auto;
    max-width: 100%;
    height: auto;
  }

}

.box {
  width: 52%;
  margin-top: 2%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  border-radius: 6px;
  box-shadow: 0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
  color: white;
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
}

