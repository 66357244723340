$greenRF: #77C35E;
$darkMainColor: #263c46;
$mainTextColor: #18202f;
$exampleTextColor: #111b1f;
$tableTitre2TextColor: #e7eced;
$tableTitre3TextColor: #f3f5f6;
.pdf {
  width: 1294px;
  min-height: 67em;
  margin: 30px auto 10px;
  text-align: center;
  @media only screen and (max-width: 800px) {
    max-width: 100%;
    max-height: 100%;
    margin: 10px auto 10px;
    .navigation {
      margin: 46px 42px 21px 10px;
      text-align: center;
      overflow: hidden;
      position: fixed;
      bottom: 0;
    }
  }

}
.pdfCanvas {
  @media only screen and (max-width: 800px) {
    max-width: 100%;
    max-height: 100%;
    margin: 10px auto 10px;
  }
}
.canvasCustom {
  overflow: hidden;
  background-color: #f1f4f5;
  @media only screen and (max-width: 800px) {
  }
}

.lesson-content {

  .iframePdf {
    width: 1294px;
    min-height: 67em;
    margin: 10px auto 10px -99px;
    @media only screen and (max-width: 800px) {
      max-width: 100%;
      max-height: 100%;
      min-height: 25em;
      margin: 10px auto 10px;
    }
  }


  h1 {
    font-size: 1.25em;
    font-weight: bold;
    text-transform: uppercase;
    color: $greenRF;
  }

  h2 {
    font-size: 1.125em;
    font-weight: bold;
    line-height: 1.71;
    color: $greenRF;
  }

  h3 {
    font-size: 1.125em;
    line-height: 1.56;
  }

  h4 {
    font-size: 1em;
    line-height: 1.56;
  }

  p, ul {
    font-size: 1em;
    line-height: 1.56;
    color: $mainTextColor;
    text-align: justify;
  }

  .texte1 {
    color: $greenRF;
    font-size: 1em;
    line-height: 1.56;
    text-align: justify;
  }

  .texte2 {
    color: $darkMainColor;
    font-size: 0.938em;
    line-height: 1.56;
    text-align: justify;
  }

  .texte3 {
    color: $darkMainColor;
    font-size: 0.875em;
    line-height: 1.56;
    text-align: justify;
  }

  img {
    width: 100%;
    height: auto;
    margin: 20px 0 30px 0;
  }

  .exemple {
    background: white;
  }

  p.exemple-titre {
    font-size: 17px;
    color: $exampleTextColor;
    padding: 10px;
  }

  p.exemple-texte {
    font-size: 16px;
    color: $exampleTextColor;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .bloc-noter {
    background: white;
  }

  p.bloc-noter-titre {
    padding: 10px;
    background: $darkMainColor;
    color: white;
    font-size: 17px;
    font-weight: bold;
  }

  p.bloc-noter-texte {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: $darkMainColor;
    font-size: 16px;
  }

  p.bloc-noter-texte1 {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: $greenRF;
    font-size: 16px;
  }

  table {
    background: white;
    border-collapse: collapse;

    th, td {
      border: 1px solid black;
      padding: 0 10px;
      font-size: 0.9em;
    }
  }

  table tr.titre1 {
    background: $darkMainColor;
  }

  table .titre1 th {
    color: white;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
  }

  table tr.titre2 {
    background: $tableTitre2TextColor;
  }

  table .titre2 th {
    color: $darkMainColor;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
  }

  table tr.titre3 {
    background: $tableTitre3TextColor;
  }

  table .titre3 th {
    color: $darkMainColor;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
  }
}
