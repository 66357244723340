
.ic-follow {
  display: block;
  width: 40px;
  height: 40px;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center center;
}
.ic-follow-812 {
  background-image: url('../../assets/ic-follow-812.svg')
}
.ic-follow-930 {
  background-image: url('../../assets/ic-follow-930.svg')
}
.ic-follow-1073 {
  background-image: url('../../assets/ic-follow-1073.svg')
}
.ic-follow-1123 {
  background-image: url('../../assets/ic-follow-1123.svg')
}
.ic-follow-1203 {
  background-image: url('../../assets/ic-follow-1203.svg')
}
.ic-follow-1374 {
  background-image: url('../../assets/ic-follow-1374.svg')
}
.ic-follow-1380 {
  background-image: url('../../assets/ic-follow-1380.svg')
}